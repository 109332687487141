let alpz = new(function() {

    this.menuMb = function() {
        $('body').toggleClass('noScroll');
    }

    // scroll
    this.onScroll = function(){
        $(window).on('scroll',function () {
            if ($(window).scrollTop() > 30)
                $('header').addClass('sticky')
            else
                $('header').removeClass('sticky')
        })
    }

    this.init = function(){
        alpz.onScroll();
        $('.hamburger').on('click', alpz.menuMb);
    }
});

(function($) {
    alpz.init();
})();