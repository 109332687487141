/* 
 * inview v2.0
 * autor: jerrydomm@gmail.com
 *  
 *
 * usage:
 * <element data-class_in="awesomeAnimation" data-class_out="awesomeAnimation" class="animationtriger inview">
 * (<div data-class_in="fadeTopIn" class="fadeIn inview"></div>)
 *
 * 
 */

const elements = document.querySelectorAll(".inview");
const options = {
  threshold: 0.2
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(entry.target.dataset.class_in);
      entry.target.classList.remove(entry.target.dataset.class_out);
    } else {
      entry.target.classList.remove(entry.target.dataset.class_in);
      if(typeof (entry.target.dataset.class_out) != 'undefined' || entry.target.dataset.class_out != null) {
        entry.target.classList.add(entry.target.dataset.class_out);
      }
    }
  });
}, options);

elements.forEach((elem, index) => {
  observer.observe(elem);
});