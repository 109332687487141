require('slick-carousel');
window.Sostenibilidad = new (function () {

    var that = this;

    this.objSldr= function(){
       
      $('.alpz-susObjSldr').slick({
          arrows: true,
          dots:false,
          slidesToScroll: 1,
          slidesToShow:1,
          infinite:false
      });
    }

    this.policyModal = function(){
        let mdlHandler = $('.alpzMdl'),
            mdlContainer = $('.alpz-modal');

            mdlContainer.removeClass('showMdl');

            mdlHandler.each((i,el) =>{
                let elem = $(el);
                elem.on('click', function(){
                    let activeTab = elem.attr('rel'),
                    content = elem.data('content'),
                    title = elem.data('title');
                    $('.alpzMdl').removeClass('active');
                    elem.addClass('active');
                    $(activeTab).addClass('showMdl');
                    $(activeTab).find('.md-modal-body').append(content);
                    $(activeTab).find('h3').append(title);
                    return false;
                });
            });

        let mdlClose = $('.md-close');

        mdlClose.on('click', function(){
            $('.alpz-modal').removeClass('showMdl').find('h3').empty();
            $('.alpz-modal').find('.md-modal-body').empty();
        });
    }
    /**
     * Init
     */
    this.init = function () {

        that.objSldr();
        that.policyModal();
    };

})();