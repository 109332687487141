(function () {
    // Helper functions
    const MathUtils = {
        map: (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c,
        lerp: (a, b, n) => (1 - n) * a + n * b,
        getRandomFloat: (min, max) => (Math.random() * (max - min) + min).toFixed(2)
    };

    function easeOutQuad(t) {
        return t * (2 - t);
    }

    function doParallax(direction, speed, element, mobile, scale, overflow) {
        // Check if element exists
        if (!element || !speed) {
            return false;
        }

        if (window.innerWidth < 768) {
            if (!mobile || mobile == 0) {
                // Remove styles on mobile to not overlap text
                element.removeAttribute('style');
                return false;
            }
        }

        if (!scale) {
            scale = '1'; // Set default scale
        }

        const parentElement = element.parentElement;
        if (!overflow) {
            parentElement.classList.remove("do-overflow");
        } else {
            parentElement.classList.add("do-overflow");
        }

        // Only do parallax if element is visible on screen for better performance
        if (checkVisible(element)) {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop,
                bodyRect = document.body.getBoundingClientRect(),
                elemRect = element.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top,
                offsetTop = scrollTop + elemRect.top,
                intersection = Math.floor((offset - scrollTop) / element.getAttribute('data-speed')),
                toValue = 1.2,
                fromValue = 1,
                scaleVal = direction === "up"
                    ? MathUtils.map(offsetTop - scrollTop, window.innerHeight, -1 * element.height, fromValue, toValue)
                    : MathUtils.map(offsetTop - scrollTop, window.innerHeight, -1 * element.height, toValue, fromValue),
                onScale = Math.max(Math.min(scaleVal, toValue), fromValue);

            if (element.getAttribute('data-scaleonscroll') && element.getAttribute('data-direction')) {
                element.style.transform = 'scale(' + onScale + ')';
            } else if (element.getAttribute('data-scale')) {
                element.style.transform = 'translateY(' + intersection + 'px) scale(' + element.getAttribute('data-scale') + ')';
            } else {
                element.style.transform = 'translateY(' + intersection + 'px)';
            }
        }
    }

    function checkVisible(elm) {
        const rect = elm.getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom <= 100 || rect.top - viewHeight >= 100);
    }

    document.onscroll = function () {
        const elmsParallax = document.querySelectorAll('.do-parallax');
        const windowW = window.innerWidth;

        for (let i = 0; i < elmsParallax.length; i++) {
            const speed = elmsParallax[i].getAttribute('data-speed');
            const scale = elmsParallax[i].getAttribute('data-scale');
            const mobile = elmsParallax[i].getAttribute('data-mobile');
            const direction = elmsParallax[i].getAttribute('data-direction');
            const overflow = elmsParallax[i].getAttribute('data-overflow');

            doParallax(direction, speed, elmsParallax[i], mobile, scale, overflow);
        }
    };
})();
