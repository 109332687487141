const StoreLocator = function() {
    
    let map;
        let markers = [];
        let infoWindows = [];

        function initMap() {
            map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 19.4326, lng: -99.1332 }, // Ciudad de México
                zoom: 6,
                mapId: "DEMO_MAP_ID"
            });

            document.getElementById('search-input').addEventListener('keyup', (event) => {
                if (event.key === 'Enter') {
                    fetchStores();
                }
            });
        }

        function clearMarkers() {
            markers.forEach(marker => marker.map = null);
            markers = [];
        }

        function clearInfoWindows() {
            infoWindows.forEach(infoWindow => infoWindow.close());
            infoWindows = [];
        }

        function clearResults() {
            const resultsBox = document.querySelector('.selectorResults');
            while (resultsBox.firstChild) {
                resultsBox.removeChild(resultsBox.firstChild);
            }
        }

        function capitalizeWords(str) {
            return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
        }

        function fetchStores() {
            const searchQuery = document.getElementById('search-input').value.trim();

            if (!searchQuery) {
                alert("Por favor, ingrese una ciudad o estado para buscar.");
                return;
            }

            fetch(`${langUrlGeocode}?query=${encodeURIComponent(searchQuery)}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Geocode request failed with status ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.error) {
                        console.error(data.error);
                        return;
                    }

                    const { lat, lng } = data;
                    map.setCenter({ lat, lng });
                    map.setZoom(10);

                    return fetch(`${langUrlStore}?query=${encodeURIComponent(searchQuery)}`);
                })
                .then(response => response.json())
                .then(stores => {
                    clearMarkers();
                    clearInfoWindows();
                    clearResults();

                    const resultsBox = document.querySelector('.selectorResults');
                    let center = { lat: 19.4326, lng: -99.1332 }; // Ciudad de México por defecto
                    let zoom = 6;

                    if (stores.length > 0) {
                        center = {
                            lat: parseFloat(stores[0].latitude),
                            lng: parseFloat(stores[0].longitude)
                        };
                        zoom = 15;
                    }

                    map.setCenter(center);
                    map.setZoom(zoom);

                    if (stores.length > 0) {
                        resultsBox.style.display = 'block';

                        stores.forEach(store => {
                            const position = { lat: parseFloat(store.latitude), lng: parseFloat(store.longitude) };

                            const markerElement = document.createElement('img');
                            markerElement.src = '/images/findus/alpezzi-pin.png';

                            const marker = new google.maps.marker.AdvancedMarkerElement({
                                map: map,
                                position: position,
                                content: markerElement,
                                title: store.name
                            });

                            const infoWindow = new google.maps.InfoWindow({
                                content: `<div class="infoWindow-title">${store.name}</div><p>${store.address}</p>`
                            });
                            
                            //advanced marker beta
                            //marker.addListener('gmp-click', () => {
                            //    clearInfoWindows();
                            //    infoWindow.open(map, marker);
                            //});

                            google.maps.event.addListener(marker, 'click', () => {
                                clearInfoWindows();
                                infoWindow.open(map, marker);
                            });

                            markers.push(marker);
                            infoWindows.push(infoWindow);

                            const resultElement = document.createElement('div');
                            resultElement.classList.add('itemResult');
                            resultElement.innerHTML = `<span>${store.name}</span><span>${capitalizeWords(store.address)}</span><span>${capitalizeWords(store.colonia)}</span><span>${capitalizeWords(store.city)}, ${capitalizeWords(store.state)}, México</span>`;
                            resultElement.addEventListener('click', () => {
                                map.setCenter(marker.position);
                                map.setZoom(16);
                                clearInfoWindows();
                                infoWindow.open(map, marker);
                                document.querySelectorAll('.itemResult').forEach(item => item.classList.remove('active'));
                                resultElement.classList.add('active');
                            });

                            resultsBox.appendChild(resultElement);
                        });
                    } else {
                        resultsBox.style.display = 'block';
                        resultsBox.style.height = 'auto';
                        const noResultsElement = document.createElement('div');
                        noResultsElement.classList.add('itemResult');
                        noResultsElement.innerHTML = '<span>No encontramos ninguna tienda cercana</span>';
                        resultsBox.appendChild(noResultsElement);
                    }
                })
                .catch(error => console.error('Error fetching stores:', error));
        }

    function hidePanel() {

        let $handler = $('.alpz-closeSelector'),
            $target = $('.alpz-selectorWrap');

        // función para abrir/cerrar panel

        function openClose() {
            $target.toggleClass('active');
            $(this).toggleClass('active');
        }

        $handler.on('click', openClose);
    };

    initMap();
    hidePanel();
};

window.StoreLocator = StoreLocator;