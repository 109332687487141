window.Contact = new (function () {
    var that = this;

    this.addDropBusiness = function(){
        let dropSubject = $('#subject')
            optDataName = $('.input_g').data('name');
        
        dropSubject.on('change', function(){
            switch($('#subject option:selected').val()){
                case "Desarrollo de proyectos":
                    $('[data-name*="servico"]').remove();
                    if($('[data-name*="desarrollo"]').length >= 0){
                        $('<div class="col-md-6 input-g" data-name="desarrollo"><label>'+ lblTypeProject +'</label><select name="tipo_proyecto" id="tipo_proyecto">' + dropProjectDevOpt +'</select></div>').insertAfter($(this).parent());
                    }
                    break;
                case "Servicio al cliente":
                        $('[data-name*="desarrollo"]').remove();
                        $('<div class="col-md-6 input-g" data-name="servico"><label>'+ lblTypeBusiness +'</label><select name="tipo_servicio">'+ dropClientServiceOpt +'</select></div>').insertAfter($(this).parent());
                        that.companyField();
                    break;
                default:
                    $('[data-name*="servico"]').remove();
                    $('[data-name*="desarrollo"]').remove();
                    that.companyField();
            }            
        }).trigger('change');

        $(document).on('change', "#tipo_proyecto", function(){
            if($('#tipo_proyecto option:selected').val() == "Food Service"){
                if(!$('#type_negocio').length >= 0){
                    $('[data-name*="company"]').empty().append('<label>'+ lblTypeComplaint +'</label><select name="tipo_negocio" id="tipo_negocio">'+ dropBusinessOpt +'</select>');
                }
            } else{
                that.companyField();
            }
        });

        if($('#tipo_proyecto option:selected').val() == "Food Service"){
            if(!$('#type_negocio').length >= 0){
                $('[data-name*="company"]').empty().append('<label>'+ lblTypeComplaint +'</label><select name="tipo_negocio" id="tipo_negocio">'+ dropBusinessOpt +'</select>');
            }
        } 
    }

    this.companyField = function(){
        if(!$('[name*="company"]').length >= 0){
            $('[data-name*="company"]').empty().append('<label>'+ lblTypeCompany +'</label><input type="text" name="empresa">');
        }
    }

    this.init = function () {
        that.addDropBusiness();
    };

})();