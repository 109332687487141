window.TabsMenu = new (function () {

    var that = this;

    this.tabsMenu = function(e){
        tabHandler = $(".tabLink"),
        tabContainer = $('.tabContent');

        tabContainer.hide();
        tabContainer.first().addClass("active");
        tabContainer.first().show();
        tabHandler.first().addClass("active");

        tabHandler.each((i,el) =>{
            let elem = $(el);
            
            elem.on('click', function(){
                let activeTab = elem.attr('href');
                $('.tabLink').removeClass('active');
                $(this).addClass('active');

                tabContainer.hide();
                $(activeTab).show();

                var $containerContent = $(".alpzContainer");
				var offsetTop         = $containerContent.offset().top - $('header').height() - $(".alpz-tabsMenu").height() - 68;
                // Animate the scroll to the calculated position for a smooth transition.
				$([document.documentElement, document.body]).animate({scrollTop: offsetTop}, 500);
              
                return false;
            });
        });
       
    }

    this.innerTab = function(e){
        let tabHandler = $(".alpz-innerTab a"),
            tabContainer = $('.alpz-innerContent');

        tabContainer.hide();
        tabContainer.first().addClass("active");
        tabContainer.first().show();
        tabHandler.first().addClass("active");

        tabHandler.each((i,el) =>{
            let elem = $(el);
            
            elem.on('click', function(){
                let activeTab = elem.attr('href');
                $('.alpz-innerTab a').removeClass('active');
                $(this).addClass('active');

                tabContainer.hide();
                $(activeTab).show();
              
                return false;
            });
        });

    }

    this.init = function () {
        that.tabsMenu();
        that.innerTab();
    };

})();